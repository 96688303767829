var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-alert",
            { attrs: { type: "info", prominent: "", outlined: "" } },
            [
              _c("div", { staticStyle: { "max-width": "800px" } }, [
                _vm._v(
                  " A space administrator will be able to modify the contents of or start applications in all current and future instances of this space, and will be able to invite new people, create new instances, delete instances or the space itself. "
                )
              ])
            ]
          ),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "mt-3",
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c("h4", { staticClass: "primary--text" }, [
                _vm._v("Email addresses")
              ]),
              _c("v-textarea", {
                attrs: {
                  required: "",
                  rows: "3",
                  "auto-grow": "",
                  disabled: _vm.numInvitationsToSend > 0
                },
                on: { input: _vm.updateEmailString },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [_vm._v("Enter user emails")]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.emailsInput,
                  callback: function($$v) {
                    _vm.emailsInput = $$v
                  },
                  expression: "emailsInput"
                }
              })
            ],
            1
          ),
          _vm.emailsInput.length
            ? _c("div", { staticClass: "mt-3" }, [
                _c("h3", { staticClass: "primary--text" }, [
                  _vm._v(
                    "The following users will receive an invitation email to become admins of this space."
                  )
                ]),
                _c(
                  "div",
                  _vm._l(_vm.emails, function(email, index) {
                    return _c("ul", { key: index, staticClass: "mt-3" }, [
                      email.status ===
                        _vm.emailValidationOutcomes.LOW_RISK_EMAIL ||
                      email.status ===
                        _vm.emailValidationOutcomes.UNKNOWN_RISK_EMAIL
                        ? _c(
                            "li",
                            [
                              _vm._v(" " + _vm._s(email.email) + " "),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-1",
                                  attrs: { "x-small": "", color: "success" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("check_circle")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-uppercase font-weight-bold"
                                    },
                                    [_vm._v("validated")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : email.status ===
                          _vm.emailValidationOutcomes.HIGH_RISK_EMAIL
                        ? _c(
                            "li",
                            [
                              _vm._v(" " + _vm._s(email.email) + " "),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-1",
                                  attrs: { "x-small": "", color: "error" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("cancel")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-uppercase font-weight-bold"
                                    },
                                    [_vm._v("validation failed")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : email.status ===
                          _vm.emailValidationOutcomes.UNVALIDATED_EMAIL
                        ? _c(
                            "li",
                            [
                              _vm._v(" " + _vm._s(email.email) + " "),
                              _c(
                                "v-chip",
                                {
                                  staticClass: "ml-1",
                                  attrs: { "x-small": "", color: "primary" }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("warning")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-uppercase font-weight-bold"
                                    },
                                    [_vm._v("validation error")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm.validatingEmails === true
                        ? _c(
                            "li",
                            [
                              _vm._v(" " + _vm._s(email.email)),
                              _c("v-progress-circular", {
                                staticClass: "ml-1",
                                attrs: {
                                  size: 20,
                                  indeterminate: "",
                                  color: "secondary"
                                }
                              })
                            ],
                            1
                          )
                        : _c("li", [_vm._v(_vm._s(email.email))])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c("InvitationErrorsDialog", {
            attrs: {
              showDialog: _vm.showInvitationErrorsDialog,
              errorType: _vm.validationErrorType,
              invalidEmails: _vm.invalidEmailsList
            },
            on: {
              proceedWithTheInvitation: function($event) {
                return _vm.inviteAdmins($event.value)
              },
              validateEmailsAgain: function($event) {
                return _vm.validateEmailsAndinviteAdmins($event.value)
              }
            }
          })
        ],
        1
      ),
      _c("v-card-actions", [
        _c(
          "div",
          {
            staticClass: "d-flex align-center justify-center flex-column",
            staticStyle: { width: "100%" }
          },
          [
            _vm.$store.state.userInfo.is_sysadmin === true
              ? _c("v-checkbox", {
                  staticClass: "mb-1 mt-5 pa-0",
                  attrs: {
                    label: "Generate invitations but don't send emails"
                  },
                  model: {
                    value: _vm.generateInvitationLinksOnly,
                    callback: function($$v) {
                      _vm.generateInvitationLinksOnly = $$v
                    },
                    expression: "generateInvitationLinksOnly"
                  }
                })
              : _vm._e(),
            _c(
              "v-btn",
              {
                staticClass: "mr-1",
                attrs: {
                  loading: _vm.numInvitationsToSend > 0 || _vm.validatingEmails,
                  disabled:
                    !_vm.valid ||
                    _vm.numInvitationsToSend !== 0 ||
                    _vm.validatingEmails,
                  color: "secondary"
                },
                on: {
                  click: function($event) {
                    return _vm.validateEmailsAndinviteAdmins(true)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                _vm._v("Invite ")
              ],
              1
            ),
            _vm.error
              ? _c(
                  "v-alert",
                  {
                    staticClass: "mt-4",
                    attrs: {
                      "max-width": "600",
                      color: "error",
                      icon: "warning",
                      text: ""
                    }
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Invitation Failure Error")
                      ]),
                      _vm._v(" Failed to invite the following users "),
                      _c(
                        "ul",
                        _vm._l(_vm.failedInvitations, function(email, index) {
                          return _c("li", { key: index }, [
                            _vm._v(" " + _vm._s(email) + " ")
                          ])
                        }),
                        0
                      )
                    ])
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }